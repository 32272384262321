import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import HeaderMenu from '../header-menu/header-menu';

import headerStyles from './header.module.css';

export const query = graphql`
  {
    prismicHeaderMedia {
      data {
        poster_image {
          url
        }
        video {
          url
        }
      }
    }
  }
`;

export default function Header({ activePageName }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <>
          <HeaderMenu activePageName={activePageName} />

          <div className={headerStyles.header}>
            <video
              className={headerStyles.video}
              role="presentation"
              playsInline
              preload="auto"
              muted
              loop
              autoPlay
              width="2541"
              height="1429"
              src={data.prismicHeaderMedia.data.video.url}
              poster={data.prismicHeaderMedia.data.poster_image.url}
            />

            <div className={headerStyles.overlay}>
              <div className={headerStyles.container}>
                <img
                  className={headerStyles.logoText}
                  src="/logo/Text/Tom_Seldon_Racing_White.svg"
                  alt="Tom Seldon Racing"
                />
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
}
