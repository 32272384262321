import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SiteMetadata from '../components/site-metadata/site-metadata';
import Header from '../components/header/header';
import RaceReportList from '../components/race-report-list/race-report-list';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

export const query = graphql`
  {
    allPrismicBlogPost(limit: 1, sort: { order: DESC, fields: first_publication_date }) {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;

export default function RaceReport() {
  return (
    <StaticQuery
      query={query}
      render={data => {
        let latestPostTitle = data.allPrismicBlogPost.edges[0]?.node?.data?.title?.text;

        let description =
          'Get the latest race reports and learn about the challenge of going from rookie racer to the front of the grid.';

        if (latestPostTitle) {
          description = `Read the ${latestPostTitle} and others from previous rounds, and learn about the challenge of going from rookie racer to the front of the grid.`;
        }

        return (
          <div>
            <SiteMetadata
              title="Race Report"
              image={
                'https://images.prismic.io/tomseldonracing/1f8552ac-ad49-44c0-ab15-7718bab17bd7_120098644_3342384515853000_6249041491084426167_o.jpg?auto=compress%2Cformat&rect=0%2C37%2C1193%2C537&w=1000&h=450'
              }
              description={description}
            />

            <Header activePageName="race-report" />

            <h1 className="text-center mt-10 mb-5">Latest news</h1>

            <div className="mb-6">
              <RaceReportList />
            </div>

            <SponsorsFooter />
            <Footer />
          </div>
        );
      }}
    />
  );
}
